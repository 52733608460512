@import 'base';

.zp-span_menu_active{
	border-bottom: solid 1px $main-color;
}

.zp-no-header .close {
    position: absolute;
    top: 6px;
    right: 6px;
}
.navbar {
    //box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}
.zp-site-mobile-menu{
    width: 100%;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 20000;
    padding-top: 20px;
    height: 100vh;
    transform: translateX(110%);
    box-shadow: -10px 0 20px -10px rgb(0 0 0 / 10%);
    transition: all .3s ease-in-out;
    background-color: #3a4339;
}

.zp-site-mobile-menu.zp-show-mobile{
    transform: translateX(0%);
}

.zp-site-mobile-menu-close.zp-js-menu-toggle span {
    color: #000;
    margin-right: 15px;
    font-size: 25px !important;
}

.zp-site-mobile-menu .zp-site-mobile-menu-header {
    width: 100%;
    float: left;
    padding-left: 30px;
    padding-right: 20px;
}

.zp-site-mobile-menu .zp-site-mobile-menu-header .zp-site-mobile-menu-close {
    float: right;
    margin-top: 8px;
}

.zp-site-mobile-menu .zp-site-mobile-menu-header .zp-site-mobile-menu-close span {
    font-size: 50px;
    display: inline-block;
    padding-left: 10px;
    padding-right: 0px;
    line-height: 1;
    cursor: pointer;
    transition: 0.3s all ease;
    color: white;
    font-weight: 100;
}

.zp-site-mobile-menu .zp-site-mobile-menu-header .zp-site-mobile-menu-close span:hover {
    color: #25262a;
}

.zp-site-mobile-menu .zp-site-mobile-menu-header .zp-site-mobile-menu-logo {
    float: left;
    margin-top: 10px;
    margin-left: 0px;
}

.zp-site-mobile-menu .zp-site-mobile-menu-header .zp-site-mobile-menu-logo a {
    display: inline-block;
    text-transform: uppercase;
}

.zp-site-mobile-menu .zp-site-mobile-menu-header .zp-site-mobile-menu-logo a img {
    max-width: 70px;
}

.zp-site-mobile-menu .zp-site-mobile-menu-header .zp-site-mobile-menu-logo a:hover {
    text-decoration: none;
}

.zp-site-mobile-menu .zp-site-mobile-menu-body {
    overflow-y: scroll !important;
    position: relative;
    padding: 20px 30px;
    height: calc(100vh - 52px);
    padding-bottom: 150px;
}

.zp-site-mobile-menu .zp-site-nav-wrap {
    padding: 30px;
    margin: 0;
    list-style: none;
    position: relative;
    float: left;
    clear: both;
    text-align: center;
}

.zp-site-mobile-menu .zp-site-nav-wrap a {
    padding: 15px 0;
    display: block;
    position: relative;
    color: #212529;
    font-weight: 500;
}

.zp-site-mobile-menu .zp-site-nav-wrap li {
    position: relative;
    display: block;
}

.zp-site-mobile-menu .zp-site-nav-wrap li.active > a {
    color: #28a745;
}
.zp-site-mobile-menu .zp-site-nav-wrap > li {
    display: block;
    position: relative;
    float: left;
    width: 100%;
}

.zp-site-mobile-menu .zp-site-nav-wrap > li > a {
    font-size: 16px;
}

.zp-site-mobile-menu .zp-site-nav-wrap > li > ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.zp-site-mobile-menu .zp-site-nav-wrap > li > ul > li {
    display: block;
}

.zp-site-mobile-menu .zp-site-nav-wrap > li > ul > li > a {
    padding-left: 40px;
    font-size: 16px;
    color: #888;
}

.zp-site-mobile-menu .zp-site-nav-wrap > li > ul > li > ul {
    padding: 0;
    margin: 0;
}

.zp-site-mobile-menu .zp-site-nav-wrap > li > ul > li > ul > li {
    display: block;
}

.zp-site-mobile-menu .zp-site-nav-wrap > li > ul > li > ul > li > a {
    font-size: 16px;
    padding-left: 60px;
}

.zp-site-mobile-menu .zp-site-mobile-menu-header .zp-site-mobile-menu-close span:hover {
    color: #25262a;
}
.zp-site-mobile-menu .zp-site-mobile-menu-close span{
    top: -14px;
    position: relative;
    margin-top: 0px ;
}
.navBar-btn{
    color: $main-color;
}
.navBar-btn:hover, .navBar-btn:focus, .navBar-btn:active{
    background-color: white;
    box-shadow: none;
    border-color: $main-color;
}

.zp-site-mobile-menu .navBar-btn{
    position: relative;
    float: left;
    margin-top: 0px;
}
.zp-username-label{
    font-size: 13px;
    color: #5a5a5a;
}

.btn:focus, .btn.focus{
    box-shadow: none !important;
}

@media screen and (min-width: 0px) and (max-width: 840px){
    .zp-navbar-container .navBar-btn{
        margin-top: 0px;
        font-size: 20px !important;
    }

    .nav-bar-logo, .nav-bar-logo-negro{
        max-width: 170px;
    }
    .navbar{
        padding: 10px;
        padding-right: 25px;
    }
    .navbar-brand{
        margin-bottom: 0px !important;
        padding: 0px;
        margin: 0px;
    }
    .nav-fixed-anuncio{
        top: 79px !important;
    }
    .vh-100{
        height: 110vh !important;
    }
    .mb-5,.my-5,.mt-5{
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .mt-10{
        margin-top: 2rem !important;
    }
    .zp-propiedad-ventaje-titulo{
        font-size: 30px !important;
        line-height: 29px;
    }
    h1{
        font-size: 1.7rem;
    }
    .zp-inmobiliaria .zp-card-container{
        margin: 0px;
        padding: 0px;
    }
    .owl-nav,.owl-dots{
        display: none;
    }

}

.zp-selector-cabecera .dropdown-toggle{
    color: $new-blue-color !important;
    font-size: 12px !important;
}
.zp-selector-cabecera .dropdown-item.active,
.zp-selector-cabecera .dropdown-item:active{
    background-color: $main-color;
}
.zp-selector-cabecera .dropdown-item.nav-link-text:after{
    border-bottom:  0px !important;
}

.zp-usuario-administrador{
    top: 1px;
    position: relative;
    color: $new-blue-color;
}
.zp-selector-cabecera a.dropdown-toggle{
    top: 4px;
    position: relative;
}

@media screen and (min-width: 100px) and (max-width: 575px){
  .zp-hide-mobile-575{
    display: none;
  }
}


@media screen and (min-width: 100px) and (max-width: 840px){
  .zp-hide-mobile-840{
    display: none;
  }
  .zp-dolar-container{
    width: 100%;
  }
  .zp-site-mobile-menu .zp-site-nav-wrap{
    //overflow: auto;
    //height: 90vh;
  }
}
